// Maintains singleton copy of user profile
// TODO: Do we want to couple Auth to profiles?
// TODO: Not sure if it's wise to return the previous user profile on clear()
angular.module('sq.user.profile.service', ['sq.user.model', 'sq.user.auth'])
.factory('userProfile', userProfileFactory);

function userProfileFactory(User, Auth, $q, $rootScope, $log, $timeout) {
  const uP = {};

  let _user = {};

  Object.defineProperties(uP, {
    user: {
      get: () => _user,
    },
  });

  // Use our own caching system to keep an up to date user model instance
  // Per recommendation of Jeff Cross (thanks!) https://github.com/angular/angular.js/issues/8307
  // handling this by checking whether we've ever had a resolution
  // Because we always intend to only fetch all data one time.
  let _initProfileResolved = false;

  const hasProfile = () => !!Object.keys(_user).length;

  const clear = function clear() {
    _user = {};
    _initProfileResolved = false;
  };

  const getProfileAsync = () => {
    if(_initProfileResolved) {
      return $q.resolve(uP.user);
    }

    if(Auth.isGuest()) {
      _user = new User(Auth.user);
      _initProfileResolved = true;

      return $q.resolve(uP.user);
    }

    return User.get().$promise.then((user) => {
      _user = user;

      _initProfileResolved = true;
      return uP.user;
    }).catch((rejection) => {
      _initProfileResolved = true;
      return rejection;
    });
  };

  /*@return A+ promise*/
  // TODO: error handling;
  uP.saveAsync = (dataObj) => {
    const maybeUpdatedUser = new User(Object.assign({}, _user, dataObj));

    return maybeUpdatedUser.$save().then((updatedUser) => {
      console.info('updated user', updatedUser);
      _user = new User(updatedUser);
    });
  };

  uP.update = (data) => {
    if(!_user) {
      $log.warn('cannot update null user');
      return;
    }

    return _user.patch(data).then((updatedUser) => {
      // Double check what happens to references in this case
      // maybe better to mutate _user
      _user = new User(updatedUser);
      //Object.assign(_user, updatedUser);
      return _user;
    });
  };

  // TODO: Do we really want to couple Auth to profiles?
  uP.initializeAsync = () => {
    return Auth.verifyLoggedInAsync().then(() => {
      return getProfileAsync();
    }).catch((err) => {
      $log.debug('not logged in, clearing profile', err);
      clear();

      return err;
    });
  };

  uP.hasProfile = hasProfile;
  uP.getProfileAsync = getProfileAsync;
  uP.clear = clear;

  // These are here simply to hide latency, and not a guarantee
  // that data will be available in time for some specific route
  // If for some reason on loggedIn we fail to get the profile, clear
  // the promise, to clear our "cache", and allow any routes that depend on this
  // data to try to resolve it again.
  uP.listen = () => {
    $rootScope.$on(`${Auth.eventPrefix}loggedIn`, () => {
      getProfileAsync().catch( () => clear() );
    });

    $rootScope.$on(`${Auth.eventPrefix}loggedOut`, () => {
      clear();
    });
  };

  return uP;
}